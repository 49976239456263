<template>
  <div>
    <!-- 面包屑 -->
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>人才招聘</BaseBreadItem>
    </BaseBread>
    <div class="link-list">
      <!-- 左：侧边栏组件 -->
      <LinkAside/>
      <!-- 右：内容 -->
      <!-- 三级路由的出口 -->
      <RouterView/>
    </div>
  </div>
</template>
<script>
import LinkAside from "./components/link-aside.vue";
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";


export default {
  name: 'HomeBanner',
  components: {BaseBreadItem, BaseBread, LinkAside},
  setup() {
    return {}
  }
}

</script>

<style scoped lang="less">
.link-list {
  display: flex;
  margin-top: 20px;
  width: 140vh;

  .article {
  }
}
</style>
